<template>
   <div class="row m-0">
      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body p-3">
               <label class="form-label w-100">
                  <i class="far fa-car-side font-12 color-theme me-1"></i> Veículo <span class="color-theme float-end">Pressione 'enter' para adicionar</span>
               </label>
               <input type="text" class="form-control searchInput" v-model="add.searchVehicle" placeholder="Pesquisar ou enter para adicionar..." @keyup.enter="setVehicleUser(add.searchVehicle)" />
            </div>
         </div>
      </div>

      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body p-3">
               <h6 class="font-16 mx-2 mt-1"><i class="fas fa-angle-right font-15 color-theme me-1"></i> Selecione um veículo</h6>

               <div id="searchVehicle" class="cards-0">
                  <div class="card mb-0 invert hover" v-for="(veiculo, index) in listaVeiculos" :key="index" @click="setVehicleUser(veiculo)">
                     <div class="card-body text-center px-2 py-3 cursor-pointer">
                        <img class="mb-2 rounded" :src="urlFotoVeiculos + veiculo +'.png'" @error="imageError" />
                        <h6 class="mb-0 mt-1 limitador weight-700 font-14">{{ veiculo }}</h6>
                     </div>
                  </div>
               </div>

               <p class="font-16 mx-2 text-center" v-if="listaVeiculos == null || listaVeiculos.length == 0">Nenhum veiculo encontrado.</p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'AdmJogadores_veículos',
   data: function () {
      return {
         add: {'veiculo': false, 'searchVehicle': ''}
      }
   },
   computed: {
      ... mapState({
         listaVeiculos: state => state.listaVeiculos,
         urlFotoVeiculos: state => state.urlFotoVeiculos,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'add.searchVehicle' : function (val) {
         var value = val.toLowerCase()

         $("#searchVehicle h6").filter(function () {
            $(this.parentElement.parentElement).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
   },
   methods : {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      setVehicleUser : function(veiculo) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Adicionar veículo',
            text: 'Informe a identidade',
            input: 'text',
            inputPlaceholder: 'Identidade',
            customClass: {
               input: 'shadow-none'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!$.isNumeric(value)) {
                  return 'Identidade inválida!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/setVehicleUser',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: parseInt(result.value),
                     vehicle: veiculo
                  }
               }).then(function () {
                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Veículo adicionado!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      }
   },
   mounted() {
      this.$store.dispatch('buscarVeiculos')
   }
}

</script>